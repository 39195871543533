<template>
  <q-layout view="lHh lpR lFr" class="min-template">
    <q-header bordered class="bg-gris-panel text-grey-system">
      <q-toolbar>
        <q-btn dense flat icon="las la-arrow-left" @click="salir()"/>
        <q-toolbar-title class="fs-16">Configuraciones</q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-drawer show-if-above side="left" :width="275" content-class="bg-gris-panel" bordered>
      <div class="text-center">
        <router-link to="/home" exact>
          <img :src="rutaLogo" style="height:50px" alt="logo"/>
        </router-link>
      </div>
      <menu-configuraciones v-if="menuConfiguraciones" />
    </q-drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: "TemplateConfiguraciones",
  components: {
    menuConfiguraciones: () => import("../menus/menuConfiguraciones"),
  },
  data() {
    return {
      menuConfiguraciones: true,
      rutaLogo: localStorage.getItem("amazonS3") + "assets/imagenes/logo-expand.png",
    };
  },
  methods: {
    salir() {
      this.$router.push("/home");
    }
  }
};
</script>
